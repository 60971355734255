import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useAuth, useFirebaseArsenal } from "../firebase/firebaseCentral"
// import Loader from '@/components/Loader';

const LoginForm = () => {
  const {
    signinWithEmail,
    signout,
    user,
    loading,
    sendPasswordResetEmail,
    confirmPasswordReset,
  } = useAuth()
  const [email, setEmail] = useState("")
  const { register, errors, handleSubmit } = useForm()

  //   console.log('sendEmailVerification', sendEmailVerification);

  const onSubmit = data => {
    console.log("data", data)
    signinWithEmail({ email: data.email, password: data.password })
  }

  const handleSignout = () => {
    signout()
  }

  const onChangeEmail = e => {
    setEmail(e.target.value)
  }
  console.log("email", email)
  const handleSendPasswordResetEmail = e => {
    sendPasswordResetEmail(email)
  }

  return (
    <>
      {/* LOGIN */}
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        <div className="rounded-md">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            Email address
          </label>
          <div className="mt-1 rounded-md">
            <input
              id="email"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 shadow-sm"
              type="email"
              name="email"
              required
              ref={register({
                required: "Please enter an email",
                pattern: {
                  value:
                    '/^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/',
                  message: "Not a valid email",
                },
              })}
            />
            {errors.email && (
              <div className="mt-2 text-xs text-red-600">
                {errors.email.message}
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            Password
          </label>
          <div className="mt-1 rounded-md">
            <input
              required
              id="password"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 shadow-sm"
              type="password"
              name="password"
              ref={register({
                required: "Please enter a password",
                minLength: {
                  value: 6,
                  message: "Should have at least 6 characters",
                },
              })}
            />
            {errors.password && (
              <div className="mt-2 text-xs text-red-600">
                {errors.password.message}
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <span className="block w-full rounded-md shadow-sm">
            {loading && (
              <div style={{ width: "10rem", color: "#181818" }}>LOADING...</div>
            )}
            {!loading && (
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                Log in
              </button>
            )}
          </span>
        </div>
      </form> */}

      {/* LOGOUT */}
      <div
        style={{
          margin: "1rem",
          padding: "1rem",
          background: "#efefef",
          cursor: "pointer",
        }}
        onClick={handleSignout}
      >
        SIGN OUT
      </div>

      {/* RESET EMAIL */}
      <div
        style={{
          margin: "1rem",
          padding: "1rem",
          background: "#efefef",
          cursor: "pointer",
        }}
      >
        RESET EMAIL
        <input onChange={onChangeEmail} />
        <button onClick={handleSendPasswordResetEmail}>Change Email</button>
      </div>
    </>
  )
}
export default LoginForm
