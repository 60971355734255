import React, { useEffect, useState } from "react"
import { useAuth, useFirebaseArsenal } from "../firebase/firebaseCentral"
import LoginForm from "../components/LoginForm"
import { useForm } from "react-hook-form"

const LoginPage = () => {
  const { user: currentUser, sendEmailVerification } = useAuth()
  const [user, setUser] = useState(null)
  const { firebase } = useFirebaseArsenal()
  const [loading, setLoading] = useState(false)
  const [capsules, setCapsules] = useState([])
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const { register, errors, handleSubmit } = useForm()

  console.log('firebase',firebase)
  useEffect(() => {
    setUser(currentUser)
  }, [currentUser])

  useEffect(() => {
    if (firebase && user?.userId) {
      async function fetchCapsules() {
        console.log("reached")
        const capsulesDocs = await firebase.db
          .collection("users")
          .doc(user.userId)
          .collection("capsules")
          .limit(10)
          .get()
        const capsulesData = capsulesDocs.docs.map(doc => doc.data())
        setCapsules(capsulesData)
      }

      fetchCapsules()
    }
  }, [firebase, user])

  console.log("capsules", capsules)
  console.log("user-----", user)
  console.log("firebase----login", firebase)

  const handleName = e => {
    setName(e.target.value)
  }

  const handleEmail = e => {
    setEmail(e.target.value)
  }
  const handlePassword = e => {
    setPassword(e.target.value)
  }
  // console.log("loading", loading)
  const onSubmit = async e => {
    e.preventDefault()

    // console.log("[name,email,password]", [name, email, password])
    // console.log("[firebase", [firebase])

    setName("")
    setEmail("")
    setPassword("")
    setLoading(true)
    const user = await firebase.auth.createUserWithEmailAndPassword(
      email,
      password
    )
    if (user) {
      sendEmailVerification();
      const createUserCallable = firebase.functions.httpsCallable("createUser")
      await createUserCallable({
        email,
        name,
      })
      setLoading(false)
    } else {
      setLoading(false)
    }

    // await firebase.register({
    //   name,
    //   email,
    //   password,
    // })
    // const testCatbearCallable = firebase.functions.httpsCallable("testCatbear")
    // await testCatbearCallable({
    //   name,
    //   email,
    //   password,
    // })
    // setLoading(false)
    // setName("")
    // setEmail("")
    // setPassword("")

    // firebase.register({
    //   username,
    //   email,
    //   password
    // });
    // .catch((err) => {
    //   console.log('err------------', err);
    // });
    // console.log("[username, email, password]", [username, email, password])
  }

  return (
    <div className="min-h-screen flex flex-col justify-center bg-gray-200">
      {loading && <div style={{ fontSize: "5rem" }}>LOADING...</div>}
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="text-center mt-24">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Log in
          </h2>
          <p className="mt-2 text-center text-md text-gray-600">
            {"Don't have an account? "}

            <a href="#" className={``}>
              Sign Up
            </a>
          </p>
        </div>
        <div className="mt-8 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <LoginForm />
        </div>
        <div className="mt-8 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div>
            <h1>Register</h1>
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <form onSubmit={onSubmit}>
              <div className="mt-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    value={name}
                    id="name"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    type="text"
                    name="name"
                    onChange={handleName}
                  />

                  {/* {errors.email && (
                    <div style={{ color: "#181818" }}>
                      {errors.email.message}
                    </div>
                  )} */}
                </div>
              </div>
              <div className="mt-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    value={email}
                    id="email"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    type="email"
                    name="email"
                    onChange={handleEmail}
                    // ref={register({
                    //   required: "Please enter an email",
                    //   pattern: {
                    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    //     message: "Enter a valid email address",
                    //   },
                    // })}
                  />

                  {/* {errors.email && (
                    <div style={{ color: "#181818" }}>
                      {errors.email.message}
                    </div>
                  )} */}
                </div>
              </div>
              <div className="mt-6">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-5 text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                  <input
                    value={password}
                    onChange={handlePassword}
                    id="password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    type="password"
                    name="password"
                    // ref={register({
                    //   required: "Please enter a password",
                    //   minLength: {
                    //     value: 6,
                    //     message: "Should have at least 6 characters",
                    //   },
                    // })}
                  />

                  {/* {errors.password && (
                    <div
                      style={{ color: "#181818" }}
                      className="mt-2 text-xs text-red-600"
                    >
                      {errors.password.message}
                    </div>
                  )}
                  {errors.password && (
                    <div className="mt-2 text-xs text-red-600">
                      {errors.password.message}
                    </div>
                  )} */}
                </div>
              </div>
              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    Sign up
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default LoginPage
